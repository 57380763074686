<!-- <?xml version="1.0" encoding="UTF-8"?>
<urn:example-namespace>
    <xbrl>
        <context id="current">
            <entity>
                <identifier scheme="http://www.example.com">BAUER 2024</identifier>
            </entity>
            <period>
                <startDate>2024-01-01</startDate>
                <endDate>2024-12-31</endDate>
            </period>
        </context>

        <unit id="USD">
            <measure>iso4217:USD</measure>
        </unit>

        <financials>
            <item contextRef="current" unitRef="USD" name="ManagementAndSustainabilityReport">
                <value>1</value>
            </item>

            <items>
                <item contextRef="current" unitRef="USD" name="Topic">
                    <value>Management</value>
                </item>
                <item contextRef="current" unitRef="USD" name="MainContent">
                    <value>Details about management and sustainability.</value>
                </item>
                <item contextRef="current" unitRef="USD" name="Accuracy">
                    <value>High</value>
                </item>
                <item contextRef="current" unitRef="USD" name="Change">
                    <value>+5%</value>
                </item>
                <item contextRef="current" unitRef="USD" name="Entity">
                    <value>BAUER EUTECH</value>
                </item>
            </items>
        </financials>

        <footer>
            <text>This document has been prepared by the Sustain-Suite...</text>
            <link>https://sustain-suite.com/</link>
        </footer>
    </xbrl>
</urn:example-namespace> -->






<ix:tag xmlns:ix="http://www.xbrl.org/2008/inlineXBRL" xmlns:esrs="http://example.com/esrs">
    <header class="card custom_card">
        <div class="d-flex justify_content_between align_item_baseline mgn-up-10">
            <div class="d-flex justify_content_start align_item_center gap_10">
                <div class="d-flex justify_content_start gap_10">
                    <div class="xbrl_element_INline_viewer">
                        <ix:nonNumeric>Inline Viewer</ix:nonNumeric>
                    </div>
                    <div class="xbrl_element">
                        <ix:nonNumeric>Report</ix:nonNumeric>
                    </div>
                </div>
                <div class="content">
                    <button matTooltip="zoom in" mat-button (click)="zoomIn()"><mat-icon>add</mat-icon></button>
                    <button matTooltip="zoom out" mat-button (click)="zoomOut()"
                        color="warn"><mat-icon>remove</mat-icon></button>
                </div>
            </div>
            <div class="d-flex  align_item_baseline">
                <div class="d-flex gap_5">
                    <div class="xbrl_element_INline_viewer">
                        <ix:nonNumeric>Highlight</ix:nonNumeric>
                    </div>
                    <div> <mat-icon (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false" class="fs_14">
                            help_outline
                        </mat-icon>
                        <div *ngIf="showTooltip" class="custom-tooltip">
                            <div class="tooltip-icon">
                                <div class="tooltip_icon_esrs"></div>
                            </div>
                            <span>esrs</span>
                        </div>
                    </div>
                </div>


                <div class="d-flex justify_content_end gap_10 align_item_center">
                    <div><input type="checkbox" (change)="onCheckboxChange($event)" [(ngModel)]="isChecked" /><span
                            class="xbrl_element mgn-lt-5">
                            <ix:nonNumeric>XBRL Elements</ix:nonNumeric>
                        </span></div>
                </div>
            </div>
        </div>
    </header>

    <div class="d-flex gap_20 scroll_card">
        <div class="zoomable-wrapper width_60 mgn-up-10 left-content" [style.transform]="zoomLevel">
            <div class="card mgn-up-10 zoomable-content card_content">
                <div>
                    <div class="text-center company_name">
                        <ix:nonNumeric>M&P Group 2024</ix:nonNumeric>
                    </div>
                    <div class="text-center mgn-up-10 company_label">
                        <ix:nonNumeric>Management and Sustainability Report</ix:nonNumeric>
                    </div>
                </div>

                <!-- <div class="mgn-up-20 content" esrs:materialityMatrix>
                    <ix:nonNumeric>Materiality Matrix</ix:nonNumeric>
                </div>
                <div>
                    <ng-container *ngFor="let topic of templateDataMatrixContent; let matrixI = index">
                        <div class="d-flex justify_content_between" (click)="scrollToTopicMatrix(matrixI)">
                            <div class="mgn-up-10 topic-name" esrs:topicName [ngClass]="getHighlightClass()">
                                <ix:nonNumeric>{{ topic['topicName'] }}</ix:nonNumeric>
                            </div>
                            <div class="topic-name" esrs:matrixValue>
                                <ix:nonFraction>1</ix:nonFraction>
                            </div>
                        </div>
                    </ng-container>
                </div> -->

                <div class="mgn-up-20 content" esrs:contents>Contents</div>
                <div class="mainContentHover_custom">
                    <ng-container *ngFor="let topic of templateData; let i = index">
                        <div class="d-flex justify_content_between" (click)="scrollToTopic(i)">
                            <div class="mgn-up-10 topic-name" esrs:topicName [ngClass]="getHighlightClass()">
                                <!-- <ix:nonNumeric>{{ topic['topicName'] }}</ix:nonNumeric> -->
                                <ix:nonNumeric>{{' [' + topic['entity'] + ']' +
                                    topic['topicName'].replace(topic['entity'] + ' ', '') }}</ix:nonNumeric>


                            </div>
                            <div class="topic-name" esrs:index>
                                <ix:nonFraction>{{ i + 1 }}</ix:nonFraction>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <footer class="mgn-up-20">
                    <div class="footer">
                        <ix:nonNumeric>This document has been prepared by the Sustain-Suite to illustrate the outline of
                            the digital reporting with examples. It accompanies the Explanatory Note and Basis for
                            Conclusions but it is not part of the Draft ESRS Set 1 XBRL Taxonomy for consultation.
                        </ix:nonNumeric>
                        <span class="company_name_footer">
                            <ix:nonNumeric>© Sustain-Suite 2024 - https://sustain-suite.com/</ix:nonNumeric>
                        </span>
                    </div>
                </footer>
            </div>

            <!-- <div class="card mgn-up-10 card_content" #contentRefs esrs:documentType="report">
                <div class="mainContentHover mainContentHover_custom">
                    <div class="topic-name-content" xbrli:contextRef="current" [ngClass]="getHighlightClass()">
                        <ix:nonNumeric>{{ 'Report' }}</ix:nonNumeric>
                    </div>
                </div>
                <div class="legends mgn-up-10">
                    <mat-icon style="color: rgb(0 123 112);" xbrli:contextRef="current">fiber_manual_record</mat-icon>
                    <p class="legend-text" xbrli:contextRef="current">Environmental</p>
                    <mat-icon style="color: rgb(15 45 132);" xbrli:contextRef="current">fiber_manual_record</mat-icon>
                    <p class="legend-text" xbrli:contextRef="current">
                        <ix:nonNumeric>Social</ix:nonNumeric>
                    </p>
                    <mat-icon style="color: rgb(245 210 85);" xbrli:contextRef="current">fiber_manual_record</mat-icon>
                    <p class="legend-text" xbrli:contextRef="current">
                        <ix:nonNumeric>Governance</ix:nonNumeric>
                    </p>
                </div>

                <div class="chart-container" xbrli:contextRef="current">
                    <div class="chart-labels">
                        <span class="x-axis-label">
                            <ix:nonNumeric>Financial Materiality</ix:nonNumeric>
                        </span>
                        <span class="y-axis-label">
                            <ix:nonNumeric>Impact Materiality</ix:nonNumeric>
                        </span>
                    </div>

                    <div class="quadrant top-left" xbrli:contextRef="impactMaterial">
                        <span class="quadrant-label">
                            <ix:nonNumeric>Impact Material Topics</ix:nonNumeric>
                        </span>
                        <div class="topic-container-wrapper">
                            <div *ngFor="let topic of impactMaterialTopics" cdkDrag [cdkDragData]="topic"
                                class="topic-container" xbrli:contextRef="{{topic.id}}">
                                <div class="bubble" [ngClass]="getHighlightClass()">
                                    <div class="dot" [ngClass]="getDotClass(topic.topicName)"></div>
                                    <p>
                                        <ix:nonNumeric>{{ topic.topicName }}</ix:nonNumeric>
                                    </p>
                                </div>
                                <div class="bubble-info">
                                    <div>
                                        <p><strong>
                                                <ix:nonNumeric>{{ topic.topicName }}</ix:nonNumeric>
                                            </strong></p>
                                        <p>
                                            <ix:nonNumeric> Impact Score:</ix:nonNumeric> <span
                                                xbrli:contextRef="{{topic.id}}">
                                                <ix:nonFraction>{{ topic.impactScore }}</ix:nonFraction>
                                            </span>
                                        </p>
                                        <p>
                                            <ix:nonNumeric> Financial Score:</ix:nonNumeric> <span
                                                xbrli:contextRef="{{topic.id}}">
                                                <ix:nonFraction>{{ topic.riskScore }}</ix:nonFraction>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="quadrant top-right" xbrli:contextRef="impactFinancial">
                        <span class="quadrant-label">
                            <ix:nonNumeric>Impact and Financially Material Topics</ix:nonNumeric>
                        </span>
                        <div class="topic-container-wrapper">
                            <div *ngFor="let topic of impactFinancialMaterialTopics" cdkDrag [cdkDragData]="topic"
                                class="topic-container" xbrli:contextRef="{{topic.id}}">
                                <div class="bubble" [ngClass]="getHighlightClass()">
                                    <div class="dot" [ngClass]="getDotClass(topic.topicName)"></div>
                                    <p>
                                        <ix:nonNumeric>{{ topic.topicName }}</ix:nonNumeric>
                                    </p>
                                </div>
                                <div class="bubble-info">
                                    <div>
                                        <p><strong>
                                                <ix:nonNumeric>{{ topic.topicName }}</ix:nonNumeric>
                                            </strong></p>
                                        <p>
                                            <ix:nonNumeric>Impact Score: </ix:nonNumeric><span
                                                xbrli:contextRef="{{topic.id}}">
                                                <ix:nonFraction>{{ topic.impactScore }}</ix:nonFraction>
                                            </span>
                                        </p>
                                        <p>
                                            <ix:nonNumeric>Financial Score: </ix:nonNumeric><span
                                                xbrli:contextRef="{{topic.id}}">
                                                <ix:nonFraction>{{ topic.riskScore }}</ix:nonFraction>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="quadrant bottom-left" xbrli:contextRef="nonMaterial">
                        <span class="quadrant-label">
                            <ix:nonNumeric>Non-Material Topics</ix:nonNumeric>
                        </span>
                        <div class="topic-container-wrapper">
                            <div *ngFor="let topic of nonMaterialTopics" cdkDrag [cdkDragData]="topic"
                                class="topic-container" xbrli:contextRef="{{topic.id}}">
                                <div class="bubble" [ngClass]="getHighlightClass()">
                                    <div class="dot" [ngClass]="getDotClass(topic.topicName)"></div>
                                    <p>
                                        <ix:nonNumeric>{{ topic.topicName }}</ix:nonNumeric>
                                    </p>
                                </div>
                                <div class="bubble-info">
                                    <div>
                                        <p><strong>
                                                <ix:nonNumeric>{{ topic.topicName }}</ix:nonNumeric>
                                            </strong></p>
                                        <p>
                                            <ix:nonNumeric>Impact Score: </ix:nonNumeric><span
                                                xbrli:contextRef="{{topic.id}}">
                                                <ix:nonFraction>{{ topic.impactScore }}</ix:nonFraction>
                                            </span>
                                        </p>
                                        <p>
                                            <ix:nonNumeric>Financial Score: </ix:nonNumeric><span
                                                xbrli:contextRef="{{topic.id}}">
                                                <ix:nonFraction>{{ topic.riskScore }}</ix:nonFraction>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="quadrant bottom-right" xbrli:contextRef="financialMaterial">
                        <span class="quadrant-label">
                            <ix:nonNumeric>Financial Material Topics</ix:nonNumeric>
                        </span>
                        <div class="topic-container-wrapper">
                            <div *ngFor="let topic of financialMaterialTopics" cdkDrag [cdkDragData]="topic"
                                class="topic-container" xbrli:contextRef="{{topic.id}}">
                                <div class="bubble" [ngClass]="getHighlightClass()">
                                    <div class="dot" [ngClass]="getDotClass(topic.topicName)"></div>
                                    <p>
                                        <ix:nonNumeric>{{ topic.topicName }}</ix:nonNumeric>
                                    </p>
                                </div>
                                <div class="bubble-info">
                                    <div>
                                        <p><strong>
                                                <ix:nonNumeric>{{ topic.topicName }}</ix:nonNumeric>
                                            </strong></p>
                                        <p>
                                            <ix:nonNumeric>Impact Score: </ix:nonNumeric><span
                                                xbrli:contextRef="{{topic.id}}">
                                                <ix:nonFraction>{{ topic.impactScore }}</ix:nonFraction>
                                            </span>
                                        </p>
                                        <p>
                                            <ix:nonNumeric>Financial Score: </ix:nonNumeric><span
                                                xbrli:contextRef="{{topic.id}}">
                                                <ix:nonFraction>{{ topic.riskScore }}</ix:nonFraction>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <footer class="mgn-up-20">
                    <div class="footer">
                        <ix:nonNumeric>This document has been prepared by the Sustain-Suite to illustrate the outline of
                            the digital reporting with examples. It accompanies the Explanatory Note and Basis for
                            Conclusions but it is not part of the Draft ESRS Set 1 XBRL Taxonomy for consultation.
                        </ix:nonNumeric>
                        <span class="company_name_footer">
                            <ix:nonNumeric>© Sustain-Suite 2024 - https://sustain-suite.com/</ix:nonNumeric>
                        </span>
                    </div>
                </footer>
            </div> -->

            <ng-container *ngFor="let content of templateData; let i = index">
                <div class="card mgn-up-10 card_content" #contentRefs esrs:documentType="report">
                    <div class="mainContentHover mainContentHover_custom">
                        <div class="topic-name-content" esrs:contentTitle>
                            <!-- <ix:nonNumeric>{{ content['topicName'] }}</ix:nonNumeric> -->
                            <ix:nonNumeric>{{' [' + content['entity'] + ']' +
                                content['topicName'].replace(content['entity'] + ' ', '') }}</ix:nonNumeric>
                        </div>
                        <ix:nonNumeric>
                            <div *ngIf="content && content['mainContent']" class="topic-name-main-content"
                                esrs:mainContent [ngClass]="getHighlightClass()"
                                matTooltip="{{content['topicName']}} +XBRL block">
                                <span *ngFor="let sentence of formatSentences(content['mainContent']); let j = index"
                                    [ngClass]="{'hover-effect': hoveredIndices[i] === j}"
                                    (mouseover)="onMouseOver(i, j)" (mouseleave)="onMouseLeave(i)" (click)="addTopicToSection(content,sentence)">
                                    {{ sentence }}.
                                </span>
                            </div>
                        </ix:nonNumeric>
                    </div>
                    <footer class="mgn-up-20">
                        <div class="footer">
                            <ix:nonNumeric>This document has been prepared by the Sustain-Suite to illustrate the
                                outline of the digital reporting with examples. It accompanies the Explanatory Note and
                                Basis for Conclusions but it is not part of the Draft ESRS Set 1 XBRL Taxonomy for
                                consultation.
                            </ix:nonNumeric>
                            <span class="company_name_footer">
                                <ix:nonNumeric>© Sustain-Suite 2024 - https://sustain-suite.com/</ix:nonNumeric>
                            </span>
                        </div>
                    </footer>
                </div>
            </ng-container>






        </div>

        <div class="card width_30 mgn-up-20 right-content" esrs:rightPanel>
            <div class="d-flex justify_content_between align_item_center" esrs:header>
                <div class="xbrl_element">
                    <ix:nonNumeric>{{factLabelFilter}}</ix:nonNumeric>
                </div>
                <div>
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon>settings</mat-icon>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <ix:nonNumeric>English</ix:nonNumeric>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="d-flex justify_content_between icon_bg" esrs:iconSection>
                <div class="mgn-up-10 d-flex justify_content_start gap_10 align_item_center">
                    <div class="headline cursor-pointer" [ngClass]="{'active': activeIcon === 'outlineUnavailable'}"
                        [matTooltip]="'Outline'" (click)="toggleSearchOutline()">
                        <mat-icon class="icon_height">view_headline</mat-icon>
                    </div>
                    <div class="search cursor-pointer" [ngClass]="{'active': activeIcon === 'search'}"
                        [matTooltip]="'Search'" (click)="toggleSearch()">
                        <mat-icon class="icon_height" >search</mat-icon>
                    </div>
                    <div class="search cursor-pointer" [ngClass]="{'active': activeIcon === 'info'}"
                        [matTooltip]="'Fact'" (click)="toggleSearchFact()">
                        <mat-icon class="icon_height">info</mat-icon>
                    </div>
                </div>
                <div class="d-flex gap_10">
                    <div class="headline" [class.disabled]="currentIndex === 0"
                        [ngClass]="{'disabled': currentIndex === 0}" [matTooltip]="'Previous'"><mat-icon
                            (click)="previousArrow()" class="icon_height">keyboard_arrow_left</mat-icon></div>
                    <div class="headline" [matTooltip]="'Next'"><mat-icon
                            (click)="nextArrow()" class="icon_height">keyboard_arrow_right</mat-icon></div>
                </div>
            </div>

            <ng-container *ngIf="showSearchInput">
                <div class="d-flex justify_content_between align_items_center mgn-up-10 gap_10 search_filter_bg">
                    <input type="text" placeholder="Search by title..." [(ngModel)]="searchQuery"
                        (input)="onSearchChange()" class="search-input" />
                    <div class="headline" [matTooltip]="'Filter'" (click)="toggleFilter()">
                        <mat-icon>filter_list</mat-icon></div>
                </div>
                <div *ngIf="showFilterOptions">
                    <div class="d-flex justify_content_between align_item_center mgn-up-10">
                        <div>Filter</div>
                        <div class="cursor-pointer" (click)="resetFilters()">Reset</div>
                    </div>
                    <div class="mgn-up-10 d-flex justify_content_between align_item_center">
                        <div>Concept Type</div>
                        <div class="width_50">
                            <select class="mat-select">
                                <option>All</option>
                                <option>Text</option>
                            </select>
                        </div>
                    </div>
                    <div class="mgn-up-10 d-flex justify_content_between align_item_center">
                        <div>Period</div>
                        <div class="width_50">
                            <select (change)="onDateChange($event)" class="mat-select">
                                <option value="all">All Dates</option>
                                <option *ngFor="let date of uniqueDates" [value]="date">{{ date | date:'d MMMM y' }}
                                </option>
                            </select>

                        </div>
                    </div>
                </div>

                <div *ngIf="searchList && searchList.length===0 " class="fact-label">
                    <div><mat-icon>search</mat-icon></div>
                    <div class="fs_25"><ix:nonNumeric>Search Unavailable</ix:nonNumeric></div>
                </div>
                <div *ngIf="searchList && searchList.length >0 " class="fact-label">
                    <ng-container *ngFor="let search of searchList;let i = index">
                        <div
                            class="mgn-up-10 search-filter_data-bg d-flex justify_content_between align_items_center searchTopic_bg">
                            <div (click)="scrollToTopic(i)"
                                [ngClass]="{'selected-topic': selectedOutlineIndexForSearch === i}"
                                class="searchTopicName">
                                <div class=" cursor-pointer"><ix:nonNumeric>{{search.topicName}}</ix:nonNumeric>
                                    </div>
                                <div class="mgn-up-5 "><ix:nonNumeric>{{search['startDate'] | date:'d MMMM y'}} to
                                    {{search['startDate']
                                    | date:'d MMMM y'}}</ix:nonNumeric></div>
                            </div>
                            <div>
                                <mat-icon (click)="openSearchFactList(i,search)"
                                    style="color: #026dce;">subdirectory_arrow_left</mat-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>


            <ng-container *ngIf="showOutlineList">
                <div *ngIf="templateData && templateData.length===0 " class="fact-label">
                    <div><mat-icon>view_headline</mat-icon></div>
                    <div class="fs_25"><ix:nonNumeric>Outline Unavailable</ix:nonNumeric></div>
                </div>
                <div *ngIf="templateData && templateData.length >0 " class="fact-label">
                    <ng-container *ngFor="let outline of templateData ;let i = index">
                        <div class="mgn-up-10 search-filter_data-bg">
                            <div class="searchTopicName cursor-pointer" (click)="scrollToTopic(i)"
                                [ngClass]="{'selected-topic': selectedOutlineIndex === i}"><ix:nonNumeric>{{outline.topicName}}</ix:nonNumeric></div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>


            <ng-container *ngIf="searchListShowHide">

                <div *ngIf="templateData && templateData.length === 0" class="fact-label" esrs:noFactAvailable>
                    <div><mat-icon>edit</mat-icon></div>
                    <div class="fs_25">
                        <ix:nonNumeric>No Fact Available</ix:nonNumeric>
                    </div>
                </div>

                <ng-container *ngIf="templateData && templateData.length > 0">
                    <div class="right_bg">
                        <div >
                            <ng-container *ngFor="let content of templateData; let i = index">
                                <div *ngIf="i === currentIndex" esrs:fact>
                                    <div class="disclousr_topic"><ix:nonNumeric> Disclosure of {{content['topicName']}}[text block]</ix:nonNumeric></div>
                                    <div *ngIf="(getDisplayContent(content)).number" class="typed_axis">Quantitative metric (percentage)</div>

                                    


                                    <div class="concept" esrs:concept>
                                        <ix:nonNumeric>Concept</ix:nonNumeric>
                                    </div>
                                    <div class="mgn-up-10">
                                        <div class="d-flex justify_content_start gap_5 topic_dot">
                                            <div class="dot"></div>
                                            <div class="topic-name" (click)="scrollToTopic(i)">
                                                <ix:nonNumeric>{{content.topicName}}</ix:nonNumeric>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="concept mgn-up-10">
                                        <ix:nonNumeric>Dimensions</ix:nonNumeric>
                                    </div>

                                    <div *ngIf="(getDisplayContent(content)).number" class="content_label mgn-bt-10  d-flex align_item_baseline gap_5">
                                        <div class="dot_matrix"></div> <div class="concept">Name of metric(s) [typed axis]<mat-icon style="color: #0094ff; font-size: 18px;padding: 9px 5px;" class="chart-icon" (click)="addTopicChart(content)" matTooltip="Show graph">
                                            bar_chart
                                        </mat-icon></div>
                                     </div>
                                     <div *ngIf="(getDisplayContent(content)).number" class="content_label mgn-bt-10 mgn-up-10 d-flex align_item_baseline gap_5">
                                         <div  class="dot_matrix"></div><div class="concept">MetricName</div>
                                     </div>

                                    <div class="d-flex justify_content_start gap_10 align_item_center mgn-up-10">
                                        <div class="concept">
                                            <ix:nonNumeric>Date</ix:nonNumeric>
                                        </div>
                                        <div class="content_label d-flex align_item_center">
                                            <ix:nonNumeric>{{content['startDate'] | date:'d MMMM y'}} to
                                                {{content['endDate'] |
                                                date:'d MMMM y'}}</ix:nonNumeric>
                                        
                                            <mat-icon style="color: #0094ff; font-size: 18px; padding: 3px 3px;" class="chart-icon" *ngIf="(getDisplayContent(content)).number"   (click)="addTopicChart(content)" matTooltip="Show graph">
                                                bar_chart
                                            </mat-icon>
                                        
                                        </div>
                                    </div>
                                    <div class="d-flex justify_content_start gap_10 align_item_center mgn-up-10"  *ngIf="(getDisplayContent(content)).text">
                                        <div class="concept">
                                            <ix:nonNumeric>Fact </ix:nonNumeric>
                                        </div>
                                        <div class="fw_400 d-flex align_item_center">
                                            <div class="company-topic-name">
                                                <ix:nonNumeric>{{ (getDisplayContent(content)).text }}</ix:nonNumeric>
                                                <!-- <ix:nonNumeric>{{content.mainContent}}</ix:nonNumeric> -->
                                            </div>
                                            <mat-icon matTooltip="Show fact" (click)="showConceptDialog(content)" style="color: #0094ff; font-size: 18px;padding: 3px 5px;">visibility</mat-icon>
                                        </div>
                                    </div>
                                    <div class="d-flex justify_content_start gap_10 align_item_baseline mgn-up-10" *ngIf="(getDisplayContent(content)).number">
                                        <div class="concept">
                                            <ix:nonNumeric>Fact Value </ix:nonNumeric>
                                        </div>
                                        <div class="fw_400 d-flex align_item_baseline">
                                            <div class="company-topic-name">
                                                <ix:nonNumeric>{{(getDisplayContent(content)).number + 'xbrli:pure'}}</ix:nonNumeric>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10" *ngIf="(getDisplayContent(content)).digitsInHundredths">
                                        <div class="concept">
                                            <ix:nonNumeric>Accuracy</ix:nonNumeric>
                                        </div>
                                        <div class="content_label">
                                            <ix:nonNumeric>
                                                <!-- {{content.accuracy ? content.accuracy : 'n/a'}}  -->
                                                {{(getDisplayContent(content)).digitsInHundredths +1 + ' hundredths'}}
                                            </ix:nonNumeric>
                                        </div>
                                    </div>
                                    <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10">
                                        <div class="concept">
                                            <ix:nonNumeric>Change</ix:nonNumeric>
                                        </div>
                                        <div class="content_label">
                                            <ng-container *ngIf="(getDisplayContent(content)).number">
                                                <ix:nonNumeric>No prior fact in this report</ix:nonNumeric>
                                            </ng-container>
                                            <ng-container *ngIf="!(getDisplayContent(content)).number">
                                                <ix:nonNumeric>{{content.change ? content.change : 'n/a'}}</ix:nonNumeric>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10">
                                        <div class="concept">
                                            <ix:nonNumeric>Entity</ix:nonNumeric>
                                        </div>
                                        <div class="content_label">
                                            <ix:nonNumeric>{{content.entity}}</ix:nonNumeric>
                                        </div>
                                    </div>
                                    <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10">
                                        <div class="concept">
                                            <ix:nonNumeric>Concept</ix:nonNumeric>
                                        </div>
                                        <div class="fw_400 d-flex" style="width: 255px;">
                                            <div class="company-topic-name">
                                                <ix:nonNumeric>esrs:{{content.topicName}}</ix:nonNumeric>
                                            </div>
                                            <div>
                                                <mat-icon (click)="copyToClipboard(content.topicName)"
                                                    style="color: #0094ff; font-size: 16px;padding: 1px 5px;"
                                                    [matTooltip]="copiedText ? 'Copied: ' + copiedText : 'Copy'">
                                                    file_copy
                                                </mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <!-- <div class=" footer_content" esrs:footer>
                                <div><ix:nonNumeric>Prepared by Sustain-Suite</ix:nonNumeric></div>
                                <div><ix:nonNumeric>© 2024 Sustain-Suite</ix:nonNumeric></div>
                            </div> -->
                        <div class="d-flex justify_content_center align_item_center gap_10 mgn-up-10 xbrl_element">
                            <div (click)="previousArrow()" [class.disabled]="currentIndex === 0"
                                [ngClass]="{'disabled': currentIndex === 0}" [matTooltip]="'Previous'">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                            </div>
                            <div> <ix:nonNumeric>{{currentIndex+1 + ' of ' + templateData.length}}</ix:nonNumeric></div>
                            <div (click)="nextArrow()" [matTooltip]="'Next'"><mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                        </div>
                    </div>

                    <ng-container>
                        <div *ngFor="let section of filterSections" class="mgn-up-20">
                            <div class="d-flex filter-label">
                                <mat-icon (click)="togglefilterSection(section)">
                                    {{ section.show ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                                </mat-icon>
                                <div class="xbrl_element">
                                    <ix:nonNumeric>{{ section.name }}</ix:nonNumeric>
                                </div>
                            </div>
                            <ng-container *ngIf="section.show">
                                <ng-container *ngIf="section.list && section.list.length === 0">
                                    <div [ngClass]="{'expanded': section.show}">
                                        <div class="xbrl_element issueText">
                                            <ix:nonNumeric>{{ section.noIssueMessage }}</ix:nonNumeric>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="section.list && section.list.length > 0">
                                    <div *ngFor="let item of section.list">
                                        <div class="search-filter_data-bg topic_name_section">
                                            <ix:nonNumeric>
                                            
                                                <ng-container *ngIf="section.name === 'References'">
                                                    <div class="d-flex gap_10 justify_content_start">
                                                        <div class="refrences_label"> <ix:nonNumeric>Name</ix:nonNumeric></div>
                                                        <div><ix:nonNumeric> {{ item.name }}</ix:nonNumeric></div>
                                                    </div>
                                                    <div class="d-flex gap_10 justify_content_start">
                                                        <div class="refrences_label"><ix:nonNumeric> Number</ix:nonNumeric></div>
                                                        <div><ix:nonNumeric> {{ item.number }}</ix:nonNumeric></div>
                                                    </div>
                                                    <div class="d-flex gap_10 justify_content_start">
                                                        <div class="refrences_label"><ix:nonNumeric>Paragraph</ix:nonNumeric></div>
                                                        <div><ix:nonNumeric> {{ item.paragraph }}</ix:nonNumeric></div>
                                                    </div>
                                                    <div class="d-flex gap_10 justify_content_start">
                                                        <div class="refrences_label"><ix:nonNumeric>Section</ix:nonNumeric></div>
                                                        <div> <ix:nonNumeric>{{ item['entity'] }}</ix:nonNumeric></div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="section.name === 'Sections'">
                                                    <ix:nonNumeric> [{{ item['entity'] }}] {{ item['topicName'].replace(item['entity'] + ' ', '') }}</ix:nonNumeric>
                                                   
                                                </ng-container>
                                            </ix:nonNumeric>

                                           
                                    
                                    
                                    
                                    </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>

                    </ng-container>


                </ng-container>
            </ng-container>
            <ng-container *ngIf="!searchListShowHide">
                <div   esrs:noFactAvailable class=no_fact_bg>
                    <div> <mat-icon class="custom_fact_icon">turned_in_not</mat-icon></div>
                    <div class="fs_18">
                        <ix:nonNumeric>No Fact Selected</ix:nonNumeric>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</ix:tag>

