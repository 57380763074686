import { AfterViewChecked, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, NO_ERRORS_SCHEMA } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CapitalizeFirstPipeModule } from '../pipes/capitaliza.module';
import { faFileExcel, faFileImage, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { MatList, MatListItem } from '@angular/material/list';
import { RouterOutlet } from '@angular/router';
import { MatAccordion, MatExpansionModule, MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedService } from '../shared.service';


@Component({
  selector: 'app-incomplete-questions-dialog',
  standalone: true,
  imports: [CommonModule, MatIconModule, CapitalizeFirstPipeModule,
    CommonModule, RouterOutlet, MatListItem, MatExpansionPanelDescription, MatList, MatExpansionPanelTitle,
    MatExpansionModule, MatExpansionPanel, MatAccordion, MatRadioButton, MatRadioModule, MatCheckboxModule,
    MatCheckbox, MatCardModule, CapitalizeFirstPipeModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule,
    MatIcon, MatProgressSpinnerModule, MatTooltipModule, FontAwesomeModule, MatStepperModule, MatProgressBarModule

  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './incomplete-questions-dialog.component.html',
  styleUrl: './incomplete-questions-dialog.component.scss'
})
export class IncompleteQuestionsDialogComponent implements AfterViewChecked {
  confirmation: boolean;
  faFilePdf = faFilePdf;
  faFileWord = faFileWord;
  faFileExcel = faFileExcel;
  faFileImage = faFileImage;
  submitted: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { incompleteQuestions: any, submittedQuestions: any, confirmation: boolean },
    private dialogRef: MatDialogRef<IncompleteQuestionsDialogComponent>,
    private cd: ChangeDetectorRef, public sharedService: SharedService) {
    console.log(data);
    this.confirmation = data.confirmation;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirmSubmit(): void {
    this.dialogRef.close(true);
  }

  getGlobalQuestionIndex(sectionIndex: number, questionIndex: number): number {
    let globalIndex = 0;
    for (let i = 0; i < sectionIndex; i++) {
      globalIndex += this.data.submittedQuestions[i].questions.length;
    }
    globalIndex += questionIndex;
    return globalIndex;
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }
  onDropdownChange(quesAns: any, event: Event) {
    const target = event.target as HTMLSelectElement; // Cast to HTMLSelectElement
    const selectedValue = target.value; // Now you can safely access 'value'
    
    quesAns.answer = selectedValue; // Update only when a selection is made
}

  onCheckboxChangeForMultiselect(quesAns: any, dropdownOption: string) {
    const answerArray: string[] = quesAns.answer || [];
    if (answerArray.includes(dropdownOption)) {
        quesAns.answer = answerArray.filter(option => option !== dropdownOption);
    } else {
        answerArray.push(dropdownOption);
        quesAns.answer = answerArray; 
    }
}
trackByFn(item: any) {
  return item.name; // or any unique identifier
}
onQuestionClick(question: string) {
  this.dialogRef.close(question); // Pass the clicked question back
}
}




