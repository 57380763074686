import { Routes } from '@angular/router';
import { TemplateViewerComponent } from './template-viewer/template-viewer.component';
import { TemplateQuestionAnswerComponent } from './question-answer/question-answer.component';
import { TaggingReportComponent } from './tagging-report/tagging-report.component';
import { TemplateTaggingReportComponent } from './template-tagging-report/template-tagging-report.component';

export const routes: Routes = [
  { path: '', redirectTo: '/view-template', pathMatch: 'full' },
  // { path: 'projects', component: ProjectsComponent },
  // { path: 'data-portal', component: DataPortalComponent },
  // { path: 'geological-info', component: GeologicalInformationComponent },
  // { path: 'subsurface-data', component: SubsurfaceDataComponent },
  // { path: 'climate-data', component: ClimateDataComponent },
  // { path: 'subsurface-water-data', component: SubsurfaceWaterDataComponent },
  // { path: 'borehole-data', component: BoreholeDataComponent },
  { path: 'view-template', component: TemplateViewerComponent },
  { path: 'template-question-answer', component: TemplateQuestionAnswerComponent },
  // { path: 'tagging-report', component: TaggingReportComponent },
  { path: 'template-tagging-report', component: TemplateTaggingReportComponent }

];
