<ng-container *ngIf="dialogData && dialogData.type && dialogData.type==='Concept'">
    <section mat-dialog-title class="dialog_title">
        <mat-icon (click)="closeDialog()" class="close_icon">close</mat-icon>
    </section>
    <section>
        <mat-dialog-content class="mat-typography" >
            <div class="pad_10">
                <ng-container *ngIf="textBinding">
                    <div class="dialog_main_content fs_14">{{dialogData.fact?.mainContent | capitalizeFirst}}</div>
                </ng-container>
                <ng-container *ngIf="!textBinding">
                    <div class="dialog_main_content">
                        <ix:nonNumeric>{{dialogData.fact?.mainContent | capitalizeFirst}}</ix:nonNumeric>
                    </div>
                </ng-container>
            </div>

        </mat-dialog-content>

        <mat-dialog-actions align="start">
            <div class="d-flex gap_10">
                <div><mat-checkbox class="example-margin" [(ngModel)]="textBinding" [color]="'warn'"><span class="check_label">Show text only</span>
                    </mat-checkbox></div>
                <div><button mat-button (click)="closeDialog()" class="close-btn-txt">Dismiss</button></div>
            </div>
        </mat-dialog-actions>

    </section>
</ng-container>

<ng-container *ngIf="dialogData && dialogData.type && dialogData.type==='numberOnMainContent'">
    <section mat-dialog-title class="dialog_title">
        <mat-icon (click)="closeDialog()" class="close_icon">close</mat-icon>
    </section>
    <section>
        <mat-dialog-content class="mat-typography">
            <div class="pad_10">
                <div id="bar-chart" style="width: 100%; height: 450px;"></div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="start">
                <div class="d-flex justify_content_start gap_5">
                
                
                    <div>
                        <button mat-raised-button class="chart_entity_btn"
                            (click)="toggleEntity();chartBtnActionEntity(isEntityToggled)" [ngClass]="{bg_remove: !isEntityToggled}">
                            <span>
                                <ix:nonNumeric>Entity: {{ isEntityToggled ? '*' : (dialogData.fact?.entity | capitalizeFirst) }}
                                </ix:nonNumeric>
                            </span>
                        </button>
                    </div>
                    <div>
                        <button mat-raised-button class="chart_entity_btn" (click)="toggleUnit();chartBtnActionUnit(isUnitToggled)"
                            [ngClass]="{bg_remove: !isUnitToggled}">
                            <span>
                                <ix:nonNumeric>Unit: {{ isUnitToggled ? '*':'Xbrl:pure' }}</ix:nonNumeric>
                            </span>
                        </button>
                    </div>
                    <div>
                        <button mat-raised-button class="chart_entity_btn"
                            (click)="togglePeriod();chartBtnActionPeriod(isPeriodToggled)" [ngClass]="{bg_remove: isPeriodToggled}">
                            <span>
                                <ix:nonNumeric>Period: {{ isPeriodToggled ? (dialogData.fact?.startDate | date: 'd MMM, y') + '
                                    to ' +
                                    (dialogData.fact?.endDate | date: 'd MMM, y') :'*' }}</ix:nonNumeric>
                            </span>
                        </button>
                    </div>
                    <div>
                        <div><button mat-button (click)="closeDialog()" class="close-btn-txt mgn-rt-10">Dismiss</button></div>
                    </div>
                </div>
               
        
        
        </mat-dialog-actions>
    </section>



</ng-container>