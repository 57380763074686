import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CapitalizeFirstPipeModule } from '../pipes/capitaliza.module';
import { RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedService } from '../shared.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import * as echarts from 'echarts';
import { EchartsxModule } from 'echarts-for-angular';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { CanvasRenderer } from 'echarts/renderers';
import { TooltipComponent, GridComponent, LegendComponent, ToolboxComponent, TitleComponent } from "echarts/components";
import { LineChart, BarChart, PieChart, RadarChart } from 'echarts/charts';


@Component({
  selector: 'app-template-tagging-report-concept-fact-dialog',
  standalone: true,
  imports: [CommonModule, MatIconModule, CapitalizeFirstPipeModule,CommonModule, RouterOutlet,MatIconModule,  MatTooltipModule, FontAwesomeModule,MatButtonModule,MatCheckboxModule,FormsModule,
    EchartsxModule, NgxEchartsDirective,
  ],
  providers:[DatePipe],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './template-tagging-report-concept-fact-dialog.component.html',
  styleUrls: ['./template-tagging-report-concept-fact-dialog.component.scss','../common.scss']
})
export class TemplateTaggingReportConceptFactDialogDialogComponent implements OnInit,AfterViewInit {
  
    textBinding:boolean=false;
    isEntityToggled: boolean = false;
    isUnitToggled: boolean = false;
    isPeriodToggled: boolean = false;





  constructor(@Inject(MAT_DIALOG_DATA) public dialogData:any,private datePipe:DatePipe,
    private dialogRef: MatDialogRef<TemplateTaggingReportConceptFactDialogDialogComponent>,
    private cd: ChangeDetectorRef, public sharedService: SharedService) {
    console.log(dialogData);
    
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    
  }
  ngAfterViewInit(): void {
  //  this.barChartInIt()
  this.chartBtnActionPeriod(false)
  }
  barChartInIt(){
    if (this.dialogData && this.dialogData.type === 'numberOnMainContent') {
      const numbers = this.extractNumbers(this.dialogData.fact.mainContent); 
      console.log(numbers)
      this.showBarGraph(numbers,this.dialogData.fact);
    }
  }
  extractNumbers(mainContent: string): number[] {
    const matches = mainContent.match(/\d+/g);
    return matches ? matches.map(Number) : [];
  }

  showBarGraph(numbers: number[],factValue:any) {
    console.log(numbers)
    const chartDom = document.getElementById('bar-chart');
    if (!chartDom) {
      console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);
    
    const option = {
      // title: {
      //   text: 'Numbers from Main Content'
      // },
      tooltip: {
        trigger: 'item', 
        formatter: (params:any) => {
          const index = params.dataIndex; 
          const value = params.value; 
          return `${params.name}<br/>Entity: ${factValue.entity}`;
        },
      },
      legend: {
        data: [factValue.entity], 
        orient: 'horizontal', // Keep it horizontal
            top: '0%', // Position legend at the top
            left: 'center', // Center the legend horizontally
            padding: [5, 10], // Optional padding for better aesthetics
      },
      xAxis: {
        type: 'category',
        data: numbers.map((_, index) => `${this.datePipe.transform( factValue.startDate,'d MMM, y') +' to ' + this.datePipe.transform( factValue.endDate,'d MMM, y')}`),
        color: '#333',
        fontSize: 14 
      },
      yAxis: {
        type: 'value',
        name: 'XBRL: Pure', 
        nameLocation: 'middle',
        nameGap: 30, 
        nameTextStyle: {
          color: '#333',
          fontSize: 14 
        },
        splitLine: {
          show: true, 
          lineStyle: {
            color: '#ccc', 
            width: 2, 
            type: 'solid' 
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7e7e7e', 
            width: 1
          }
        }
      },
      series: [{
        name: factValue.entity,
        type: 'bar',
        data: numbers,
        itemStyle: {
          color: '#66ca00' 
        }
      }]
    };

    myChart.setOption(option);
  }

  toggleEntity() {
    this.isEntityToggled = !this.isEntityToggled;
  }

  toggleUnit() {
    this.isUnitToggled = !this.isUnitToggled;
  }

  togglePeriod() {
    this.isPeriodToggled = !this.isPeriodToggled;
  }
  chartBtnActionPeriod(toggle:boolean){
    console.log(toggle)
    if ( toggle===true) {
      const numbers = this.extractNumbers(this.dialogData.fact.mainContent); 
      this.showBarGraphPeriod(numbers,this.dialogData.fact);
    }
    else{
      this.barChartInIt()
    }

  }

  showBarGraphPeriod(numbers: number[],factValue:any) {
    console.log(numbers)
    const chartDom = document.getElementById('bar-chart');
    if (!chartDom) {
      console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);
    const startDate = this.datePipe.transform(factValue.startDate, 'd MMM, y');
    const endDate = this.datePipe.transform(factValue.endDate, 'd MMM, y');
        const legendTitle = `${factValue.entity} (${startDate} to ${endDate})`;
    const option = {
      
      tooltip: {
        trigger: 'item', 
        formatter: (params:any) => {
          const index = params.dataIndex; 
          const value = params.value; 
          return `${params.name}<br/>Entity: ${factValue.entity}`;
        },
      },
      legend: {
        data: [legendTitle], 
        orient: 'horizontal', 
            top: '0%',
            left: 'center',
            padding: [5, 10], 
      },
      xAxis: {
        type: 'category',
        data: numbers.map(() => legendTitle),
        color: '#333',
        fontSize: 14 
      },
      yAxis: {
        type: 'value',
        name: 'XBRL: Pure', 
        nameLocation: 'middle',
        nameGap: 30, 
        nameTextStyle: {
          color: '#333',
          fontSize: 14 
        },
        splitLine: {
          show: true, 
          lineStyle: {
            color: '#ccc', 
            width: 2, 
            type: 'solid' 
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7e7e7e', 
            width: 1
          }
        }
      },
      series: [{
        name: legendTitle, 
        type: 'bar',
        data: numbers,
        itemStyle: {
          color: '#66ca00' 
        }
      }]
    };

    myChart.setOption(option);
  }
  chartBtnActionUnit(toggle:boolean){
    console.log(toggle)
    if ( toggle===true) {
      const numbers = this.extractNumbers(this.dialogData.fact.mainContent); 
      this.barChartInItUnit(numbers,this.dialogData.fact);
    }
    else{
      const numbers = this.extractNumbers(this.dialogData.fact.mainContent); 
      this.showBarGraphUnit(numbers,this.dialogData.fact)
    }
  }

  showBarGraphUnit(numbers: number[],factValue:any) {
    console.log(numbers)
    const chartDom = document.getElementById('bar-chart');
    if (!chartDom) {
      console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);
        const legendTitle = `${factValue.entity}`;
    const option = {
      
      tooltip: {
        trigger: 'item', 
        formatter: (params:any) => {
          const index = params.dataIndex; 
          const value = params.value; 
          return `${params.name}<br/>Entity: ${factValue.entity}`;
        },
      },
      legend: {
        data: [legendTitle], 
        orient: 'horizontal', 
            top: '0%',
            left: 'center',
            padding: [5, 10], 
      },
      xAxis: {
        type: 'category',
        data: numbers.map((_, index) => `${this.datePipe.transform( factValue.startDate,'d MMM, y') +' to ' + this.datePipe.transform( factValue.endDate,'d MMM, y')}`),
        color: '#333',
        fontSize: 14 
      },
      yAxis: {
        type: 'value',
        name: 'XBRL: Pure', 
        nameLocation: 'middle',
        nameGap: 30, 
        nameTextStyle: {
          color: '#333',
          fontSize: 14 
        },
        splitLine: {
          show: true, 
          lineStyle: {
            color: '#ccc', 
            width: 2, 
            type: 'solid' 
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7e7e7e', 
            width: 1
          }
        }
      },
      series: [{
        name: legendTitle, 
        type: 'bar',
        data: numbers,
        itemStyle: {
          color: '#66ca00' 
        }
      }]
    };

    myChart.setOption(option);
  }
  barChartInItUnit(numbers: number[],factValue:any) {
    console.log(numbers)
    const chartDom = document.getElementById('bar-chart');
    if (!chartDom) {
      console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);
        const legendTitle = `xbrl:pure`;
    const option = {
      
      tooltip: {
        trigger: 'item', 
        formatter: (params:any) => {
          const index = params.dataIndex; 
          const value = params.value; 
          return `${params.name}<br/>Entity: ${factValue.entity}`;
        },
      },
      legend: {
        data: [legendTitle], 
        orient: 'horizontal', 
            top: '0%',
            left: 'center',
            padding: [5, 10], 
      },
      xAxis: {
        type: 'category',
        data: numbers.map((_, index) => `${this.datePipe.transform( factValue.startDate,'d MMM, y') +' to ' + this.datePipe.transform( factValue.endDate,'d MMM, y')}`),
        color: '#333',
        fontSize: 14 
      },
      yAxis: {
        type: 'value',
        name: 'XBRL: Pure', 
        nameLocation: 'middle',
        nameGap: 30, 
        nameTextStyle: {
          color: '#333',
          fontSize: 14 
        },
        splitLine: {
          show: true, 
          lineStyle: {
            color: '#ccc', 
            width: 2, 
            type: 'solid' 
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7e7e7e', 
            width: 1
          }
        }
      },
      series: [{
        name: legendTitle, 
        type: 'bar',
        data: numbers,
        itemStyle: {
          color: '#66ca00' 
        }
      }]
    };

    myChart.setOption(option);
  }
  chartBtnActionEntity(toggle:boolean){
    console.log(toggle)
    if ( toggle===true) {
      const numbers = this.extractNumbers(this.dialogData.fact.mainContent); 
      this.barChartInItEntity(numbers,this.dialogData.fact);
    }
    else{
      const numbers = this.extractNumbers(this.dialogData.fact.mainContent); 
      this.barChartInItEntity(numbers,this.dialogData.fact)
    }
  }

 
  barChartInItEntity(numbers: number[],factValue:any) {
    console.log(numbers)
    const chartDom = document.getElementById('bar-chart');
    if (!chartDom) {
      console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);
        const legendTitle = `${factValue.entity}`;
    const option = {
      
      tooltip: {
        trigger: 'item', 
        formatter: (params:any) => {
          const index = params.dataIndex; 
          const value = params.value; 
          return `${params.name}<br/>Entity: ${factValue.entity}`;
        },
      },
      legend: {
        data: [legendTitle], 
        orient: 'horizontal', 
            top: '0%',
            left: 'center',
            padding: [5, 10], 
      },
      xAxis: {
        type: 'category',
        data: numbers.map((_, index) => `${this.datePipe.transform( factValue.startDate,'d MMM, y') +' to ' + this.datePipe.transform( factValue.endDate,'d MMM, y')}`),
        color: '#333',
        fontSize: 14 
      },
      yAxis: {
        type: 'value',
        name: 'XBRL: Pure', 
        nameLocation: 'middle',
        nameGap: 30, 
        nameTextStyle: {
          color: '#333',
          fontSize: 14 
        },
        splitLine: {
          show: true, 
          lineStyle: {
            color: '#ccc', 
            width: 2, 
            type: 'solid' 
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7e7e7e', 
            width: 1
          }
        }
      },
      series: [{
        name: legendTitle, 
        type: 'bar',
        data: numbers,
        itemStyle: {
          color: '#66ca00' 
        }
      }]
    };

    myChart.setOption(option);
  }
}




