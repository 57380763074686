import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, NO_ERRORS_SCHEMA, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatList, MatListItem } from '@angular/material/list';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatAccordion, MatExpansionModule, MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatRadioButton, MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { CapitalizeFirstPipeModule } from '../pipes/capitaliza.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { IncompleteQuestionsDialogComponent } from '../incomplete-questions-dialog/incomplete-questions-dialog.component';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileExcel, faFileImage, faFilePdf, faFileWord, faCircle } from '@fortawesome/free-solid-svg-icons';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { CongratulationsComponent } from '../congratulations/congratulations.component';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { SharedService } from '../shared.service';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { TemplateViewerService } from '../template-viewer/template-viewer.service';
import { SnackbarService } from '../template-viewer/snackbar.service';
import { projectMessageLocal } from '../template-viewer/project-message';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { environment } from '../../environments/environment';
import { TemplateTaggingReportConceptFactDialogDialogComponent } from '../template-tagging-report-concept-fact-dialog/template-tagging-report-concept-fact-dialog.component';
// financial-data.model.ts
export interface FinancialData {
  id: number;
  name: string;
  value: number;
  currency: string;
}


interface FilterSection {
  name: string;
  show: boolean;
  list: any[];
  noIssueMessage: string;
}
@Component({
  selector: 'app-template-tagging-report',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatIconModule, MatButtonModule, MatMenuModule, ClipboardModule, MatTooltipModule,MatDialogModule],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [TemplateViewerService], // Make sure TemplateViewerService is provided
  templateUrl: './template-tagging-report.component.html',
  styleUrls: ['./template-tagging-report.component.scss', '../common.scss']
})
export class TemplateTaggingReportComponent implements OnInit {


  ixbrlContent: string = '';
  financialData: FinancialData[] = [];
  esefReport: string = '';

  public templateData: any[] = [];
  public templateDataSentenceFact:any;
  @ViewChildren('contentRefs') contentRefs!: QueryList<ElementRef>;
  @ViewChild('contentRefs', { static: false }) contentRefss!: ElementRef;

  visibilityStates: boolean[] | undefined;
  zoomLevel: string = 'scale(1)'; // Default zoom level
  zoomFactor: number = 1; // Base zoom factor
  factLabelFilter: string = 'Fact Properties';
  // filterContent:any[]=[];
  currentIndex: number = 0;
  showValidation: boolean = false;
  validationList: any[] = []; // Replace with your actual validation data
  filterSections: FilterSection[]  = [
    { name: 'Validations', show: false, list: [], noIssueMessage: 'No validation issues found' },
    { name: 'References', show: false, list: [], noIssueMessage: 'No references available' },
    { name: 'Calculation', show: false, list: [], noIssueMessage: 'No calculations required' },
    { name: 'Footnotes', show: false, list: [], noIssueMessage: 'No footnotes provided' },
    { name: 'Sections', show: false, list: [], noIssueMessage: 'No sections to display' }
  ];

  searchFactList: any[] = []
  outlineList: any[] = [];
  showSearchInput: boolean = false;
  searchQuery: string = '';
  activeIcon: string = 'info';
  showOutlineList: boolean = false;
  searchListShowHide: boolean = false;
  searchList: any[] = [];


  reportResponse: any;
  environmentalImpacts: any[] = [];
  environmentalSubtopic: any[] = [];
  socialImpacts: any[] = [];
  governanceImpacts: any[] = [];
  environmentalRisks: any[] = [];
  socialRisks: any[] = [];
  governanceRisks: any[] = [];

  impactMateriality: any = [];
  noinMaterial: any = [];

  impactMaterialityRisk: any = [];
  noinMaterialFinanciual: any = [];
  currentIndexHover: number = -1;
  uniqueDates: Date[] = [];
  showFilterOptions: boolean = false;
  selectedConceptType: string = 'All';
  selectedDate: string = 'all';
  topicData: any;
  impactMaterialTopics: any;
  nonMaterialTopics: any;
  financialMaterialTopics: any;
  impactFinancialMaterialTopics: any;

  environmentalMaterial: any[] = [];
  socialMaterial: any[] = [];
  governanceMaterial: any[] = [];
  environmentalNonMaterial: any[] = [];
  socialNonMaterial: any[] = [];
  governanceNonMaterial: any[] = [];
  checkboxChecked = false;
  highlightXBRLElements: boolean = false;
  hoveredElement: boolean = false;
  hoveredIndices: (number | null)[] = []; 
  copiedText: string | null = null;
  selectedOutlineIndex: number | null = null;
  selectedOutlineIndexForSearch: number | null = null;
  showTooltip: boolean = false;
  templateDataMatrixContent = this.sharedService.getTableMatrixcontent();
  isChecked: boolean = false;
  @ViewChild('contentContainer') contentContainer!: ElementRef;
  @ViewChild('content') contentTemplate!: TemplateRef<any>;
  factValue: any;
  currentContent: string | null = null; 

  constructor(private sharedService: SharedService, private templateService: TemplateViewerService,private dialog:MatDialog) { }

  // ngOnInit() {
  // this.ixbrlContent = `
  // <!DOCTYPE html>
  // <html xmlns="
  // http://www.w3.org/1999/xhtml"
  // lang="en">
  // <head>
  // <title>iXBRL Report</title>
  // </head>
  // <body>
  // <h1>Sustainability Report</h1>
  // <h2>Environmental Metrics</h2>
  // <p><b>Greenhouse Gas Emissions:</b> <xbrl:tag id="ghgEmissions">1000</xbrl:tag> tons CO2</p>
  // <p><b>Energy Consumption:</b> <xbrl:tag id="energyConsumption">50000</xbrl:tag> kWh</p>
  // <h2>Social Metrics</h2>
  // <p><b>Employee Turnover:</b> <xbrl:tag id="employeeTurnover">5</xbrl:tag>%</p>
  // <p><b>Diversity:</b> <xbrl:tag id="workforceDiversity">40</xbrl:tag>%</p>
  // <h2>Governance Metrics</h2>
  // <p><b>Board Diversity:</b> <xbrl:tag id="boardDiversity">30</xbrl:tag>%</p>
  // <p><b>Anti-Corruption Measures:</b> <xbrl:tag id="antiCorruption">Yes</xbrl:tag></p>
  // </body>
  // </html>
  //     `;
  //   }

  //   getFinancialData(): FinancialData[] {
  //     // Replace this with actual data fetching logic
  //     return [
  //       { id: 1, name: 'Revenue', value: 500000, currency: 'EUR' },
  //       { id: 2, name: 'Expenses', value: 300000, currency: 'EUR' },
  //       // Add more data as needed
  //     ];
  //   }

  ngOnInit() {
    this.getTemplateContent();

    this.getMateriality()
  }
  getTemplateContent() {
    this.templateService.getTopics(1, 1).subscribe({
      next: (response) => {
        console.log('response', response)
        this.templateData = response;
        this.searchList = [...this.templateData];
        console.log('topic' + this.templateData);
        this.currentContent=null;
      },
      error: (error) => {
        console.error('Error fetching topics ' + error);
      }
    });


  }
  generateUniqueDates() {
    const datesSet = new Set<string>();
    this.templateData.forEach(item => {
      const dateStr = item.startDate.toDateString();
      datesSet.add(dateStr);
    });
    this.uniqueDates = Array.from(datesSet).map(dateStr => new Date(dateStr));
  }

  scrollToTopicMatrix(index: number) {
    const contentRefsArray = this.contentRefs.toArray();
    if (contentRefsArray[index]) {
      const element = contentRefsArray[index].nativeElement;
      element.scrollIntoView({ behavior: 'smooth' });

    }
  }
  scrollToTopic(index: number) {

    const contentRefsArray = this.contentRefs.toArray();
    const nextIndex = index ;//for now currentIndex===nextIndex
    // const nextIndex = index + 1;

    this.selectedOutlineIndex = index;
    this.selectedOutlineIndexForSearch = index;
    this.currentContent=null;
    if (contentRefsArray[nextIndex]) {
      const element = contentRefsArray[nextIndex].nativeElement;
      element.scrollIntoView({ behavior: 'smooth' });
    } 
    // const contentRefsArray = this.contentRefs.toArray();
    // const nextIndex = index + 1;
    // this.selectedOutlineIndex = index;
    // this.selectedOutlineIndexForSearch = index;


    // if (contentRefsArray[nextIndex]) {
    //   const nextElement = contentRefsArray[nextIndex].nativeElement;
    //   const headerOffset = 100;
    //   const nextElementPosition = nextElement.getBoundingClientRect().top + window.scrollY;
    //   const offsetPosition = nextElementPosition - headerOffset;

    //   // Scroll to the calculated position
    //   window.scrollTo({
    //     top: offsetPosition,
    //     behavior: 'smooth'
    //   });
    // } 
    else {
      console.log('No next topic available.');
    }
  }
  previousArrow() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      // this.scrollToCurrent();
      this.scrollToTopic(this.currentIndex)
    }
  }

  nextArrow() {
    if (this.currentIndex < this.templateData.length - 1) {
      this.currentIndex++;
      // this.scrollToCurrent();
      this.scrollToTopic(this.currentIndex)
    }
  }

  scrollToCurrent() {
    // const nextIndex = this.currentIndex + 1;
    const nextIndex = this.currentIndex ;//for now currentIndex===nextIndex
    const contentRefsArray = this.contentRefs.toArray();
    this.currentContent=null;
    if (contentRefsArray[nextIndex]) {
      const nextElement = contentRefsArray[nextIndex].nativeElement;
      const headerOffset = 100;
      const nextElementPosition = nextElement.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = nextElementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    } else {
      console.log('No next topic available.');
    }
  }



  zoomIn() {
    this.zoomFactor += 0.1;
    this.updateZoom();
  }

  zoomOut() {
    if (this.zoomFactor > 1) {
      this.zoomFactor -= 0.1;
      this.updateZoom();
    }
  }

  updateZoom() {
    this.zoomLevel = `scale(${this.zoomFactor})`;
    if (this.contentContainer && this.contentContainer.nativeElement) {
      this.contentContainer.nativeElement.style.transform = this.zoomLevel;
      this.adjustPosition();
    }
  }

  adjustPosition() {
    if (this.contentContainer) {
      const rect = this.contentContainer.nativeElement.getBoundingClientRect();
      // Adjust position if the bottom goes beyond the window height
      if (rect.bottom > window.innerHeight) {
        const offset = rect.bottom - window.innerHeight;
        this.contentContainer.nativeElement.style.transform = `translateY(-${offset}px) ${this.zoomLevel}`;
      }
    }
  }


  // togglefilterSection(section: any) {
  //   section.show = !section.show;
  // }
 
  toggleSearch() {
    this.currentContent=null;
    this.activeIcon = 'search';
    this.showSearchInput = !this.showSearchInput;
    this.searchListShowHide=false;
    this.showOutlineList=false;
  }
  toggleSearchOutline(){
    this.currentContent=null;
    this.activeIcon = 'outlineUnavailable';
  this.showOutlineList= !this.showOutlineList  ;
  this.searchListShowHide=false;
  this.showSearchInput=false
  }
  toggleSearchFact(){
    this.currentContent=null;
    this.activeIcon = 'info';
    this.searchListShowHide= !this.searchListShowHide;
    this.showSearchInput=false;
    this.showOutlineList=false
  }

  onSearchChange() {
    console.log('searchQuery', this.searchQuery);
    this.currentContent=null;
    if (this.searchQuery) {
      const filteredList = this.templateData.filter(item =>
        item.topicName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
      this.searchList = [...filteredList];
    } else {
      this.searchList = [...this.templateData];
    }
    console.log('searchList', this.searchList);
  }


  openSearchFactList(index:number,search:any) {
    console.log('search',search)
    this.searchListShowHide = true;
    this.showSearchInput=false;
    this.navigateToSection(index);
    this.addTopicToSection(search,search.mainContent)

  }
  // navigateToSection(){
  //   if (this.contentRefss) {
  //     this.contentRefss.nativeElement.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }

  navigateToSection(index: number) {
    // Scroll to the content section corresponding to the clicked outline
    const elements = this.contentRefs.toArray();
    if (elements[index]) {
      elements[index].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  getChartResult() {
    this.templateService.getBubbleChartResult(environment.workspaceId, environment.userId).subscribe({
      next: (response) => {
        console.log('Service response:', response);
        this.impactMateriality = response.filter((item: { overAllEvaluation: number; }) => item.overAllEvaluation < 2.5);
        this.noinMaterial = response.filter((item: { overAllEvaluation: number; }) => item.overAllEvaluation >= 2.5);
        this.impactMaterialityRisk = response.filter((item: { type: string; overAllEvaluation: number; }) => item.type === 'IMA' && item.overAllEvaluation < 2.5);
        this.noinMaterialFinanciual = response.filter((item: { type: string; overAllEvaluation: number; }) => item.type === 'FMA' && item.overAllEvaluation >= 2.5);
        console.log(this.impactMaterialityRisk);
        console.log(this.noinMaterialFinanciual)

      },
      error: (error) => {
        console.error('Service error:', error);
        // this.isLoading = false;
      }
    });
  }

  onDateChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedDate = selectElement.value;

    if (selectedDate === 'all') {
      this.searchList = [...this.templateData]; // Reset to all data
    } else {
      const date = new Date(selectedDate);
      this.searchList = this.templateData.filter(item =>
        item.startDate.toDateString() === date.toDateString()
      );
    }
    console.log('Filtered searchList', this.searchList);
  }

  toggleFilter() {
    this.showFilterOptions = !this.showFilterOptions;
  }
  resetFilters() {
    this.selectedConceptType = 'All';
    this.selectedDate = 'all';
    this.showFilterOptions = false;
    this.searchList = [...this.templateData];
  }


  getMateriality() {
    this.templateService.getMateriality(1, 1).subscribe({
      next: (response: any) => {
        console.log('Materiality response:', response);
        this.impactMaterialTopics = response.impactMaterialTopics || [];
        this.nonMaterialTopics = response.nonMaterialTopics || [];
        this.financialMaterialTopics = response.financialMaterialTopics || [];
        this.impactFinancialMaterialTopics = response.impactFinancialMaterialTopics || [];
        this.environmentalMaterial = response.impactMaterialTopics.filter((topic: any) => topic.topicName.startsWith('E'));
        this.socialMaterial = response.impactMaterialTopics.filter((topic: any) => topic.topicName.startsWith('S'));
        this.governanceMaterial = response.impactMaterialTopics.filter((topic: any) => topic.topicName.startsWith('G'));
        this.environmentalNonMaterial = response.nonMaterialTopics.filter((topic: any) => topic.topicName.startsWith('E'));
        this.socialNonMaterial = response.nonMaterialTopics.filter((topic: any) => topic.topicName.startsWith('S'));
        this.governanceNonMaterial = response.nonMaterialTopics.filter((topic: any) => topic.topicName.startsWith('G'));
      },
      error: (error: any) => {
        console.error('Service error:', error);
      }
    });
  }

  getDotClass(topicName: string): string {
    if (topicName.startsWith('E')) {
      return 'dot-green';
    } else if (topicName.startsWith('S')) {
      return 'dot-blue';
    } else if (topicName.startsWith('G')) {
      return 'dot-yellow';
    } else {
      return '';
    }
  }



  onCheckboxChange(event: Event): void {
    this.checkboxChecked = (event.target as HTMLInputElement).checked;

    const checkbox = event.target as HTMLInputElement;
    this.highlightXBRLElements = checkbox.checked;
  }

  getHighlightClass() {
    return this.highlightXBRLElements ? 'highlight' : '';
  }

 

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this.copiedText = text;
      setTimeout(() => this.copiedText = null, 3000);
    });
  }




  togglefilterSection(sectionToToggle:any) {
    this.currentContent=null;
    this.filterSections.forEach(section => {
      if (section === sectionToToggle) {
        section.show = !section.show; 
      } else {
        section.show = false;
      }
    });
  }


formatSentences(input: string): string[] {
  return input.split('.').map(sentence => sentence.trim()).filter(sentence => sentence);
}


onMouseOver(contentIndex: number, sentenceIndex: number): void {
  this.hoveredIndices[contentIndex] = sentenceIndex; 
}

onMouseLeave(contentIndex: number): void {
  this.hoveredIndices[contentIndex] = null; 
}

showConceptDialog(content:any){
  console.log(content);
  const dialogRef = this.dialog.open(TemplateTaggingReportConceptFactDialogDialogComponent, {
    width: '1200px',
    height:'600px',
    disableClose: true,
    data: {
      type:'Concept',
      fact: content
    }
  });
  dialogRef.afterClosed().subscribe((result: any) => {
    console.log(result)
  })
}
// addTopicToSection(topic: any): void {
//   console.log(topic)
//   const sectionName = 'Sections';
//   const section = this.filterSections.find(s => s.name === sectionName);
//   console.log(section)

//   if (section && !section.list.some(item => item.topicName === topic.topicName)) {
//     section.list.push(topic); 
//   }

//   console.log(' this.filterSections', this.filterSections)
// }
  addTopicToSection(topic: any,sentence:any): void {
    console.log(topic);
    this.searchListShowHide=true;
    const index = this.templateData.findIndex(item => item.topicId === topic.topicId );
    console.log('index',index)
    if (index !== -1) {
        this.currentIndex = index; 
        // this.factValue = sentence; 
        this.currentContent = sentence; // Store the updated sentence temporarily
    }
    const referencesSectionName = 'References';
    const sectionName = 'Sections';
    if (referencesSectionName === 'References') {
      const fullStopCount = (topic.mainContent.match(/\./g) || []).length;
        console.log('Number of full stops in mainContent:', fullStopCount);

      let section = this.filterSections.find(s => s.name === referencesSectionName);
      console.log('section', section)
      const reference = {
        name: 'ESRS',
        number: 'ESRS 2',
        paragraph:fullStopCount,
        entity:topic.entity
      };
      if (section && !section.list.some(item => item.topicName === topic.topicName)) {
        section.list=[]
        section.list.push(reference);
      }

    }
     if(sectionName === 'Sections') {
      console.log(topic)
      const section = this.filterSections.find(s => s.name === sectionName);
      console.log(section)

      if (section && !section.list.some(item => item.topicName === topic.topicName)) {
        section.list=[]
        section.list.push(topic);
      }

      console.log(' this.filterSections', this.filterSections)
    }


    console.log('this.filterSections', this.filterSections);
    this.numberInMainContent(topic);
  }
  addTopicChart(topic:any){
    console.log(topic);
    const numbers = this.extractNumbers(topic.mainContent);
  console.log('Extracted numbers:', numbers);
  this.showBarGraph(topic);

  }
  extractNumbers(mainContent: string): number[] {
    const matches = mainContent.match(/\d+/g); 
    return matches ? matches.map(Number) : []; 
  }
  numberInMainContent(topic:any){
    const mainContentNumber=this.hasNumbers(topic.mainContent);
    return mainContentNumber;

  }
  hasNumbers(mainContent: string): boolean {
    return /\d/.test(mainContent);
  }

  showBarGraph(number: any) {
    console.log(number);
    const dialogRef = this.dialog.open(TemplateTaggingReportConceptFactDialogDialogComponent, {
      width: '1200px',
      height: '600px',
      disableClose: true,
      data: {
        type: 'numberOnMainContent',
        fact: number
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    })
  }

 
  extractNumber(content: string): string | null {
    const regex = /(\d+(\.\d+)?)/g; 
    const matches = content.match(regex);

    if (matches) {
      const decimalNumbers = matches.map(num => {
        const decimalValue = (parseFloat(num) / 100).toFixed(2); 
        return decimalValue.startsWith('0.') ? decimalValue : '.' + decimalValue.split('.')[1]; 
      });
      return decimalNumbers.join(', '); 
    }
    return null; 
  }

  getDisplayContent(content: any): { number: string | null, hundredths: string | null, text: string, digitsInHundredths: number } {
    const extractedNumber = this.extractNumber(this.currentContent || ''); 
    let hundredths: string | null = null;
    let digitsInHundredths: number = 0;

    if (extractedNumber) {
        const decimalValue = parseFloat(extractedNumber);
        if (!isNaN(decimalValue)) {
            const hundredthsValue = Math.floor(decimalValue * 100); 
            hundredths = `${hundredthsValue} (hundredths)`;
            const decimalString = decimalValue.toString();
            const decimalPart = decimalString.split('.')[1]; 
                        if (decimalPart) {
                digitsInHundredths = decimalPart.length;
            }
        }
    }

    const text = (this.currentContent || content.mainContent); 

    return { number: extractedNumber, hundredths: hundredths, text: text, digitsInHundredths: digitsInHundredths };
}




}



