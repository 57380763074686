

<ng-container *ngIf="!showCongratulations; else congratsTemplate">
  <section class="template-bg">

      <header class="logo-header">
        <div class="logo-title">
          <div >
            <img   loading="lazy" [src]="'https://siera-alliance.org/wp-content/uploads/2024/07/Suera-Alliance-logo-design-02-e1720774375866-1024x357.png' " alt="Logo Image" class="logo-image">

          </div>
          <div class="assessment_label_bg">{{ data?.title | capitalizeFirst }}</div>
        </div>
        <div class="phone-number-bg">
          <mat-icon class="phone-icon">phone</mat-icon>
          <span class="phone-number"><a class="phoneNumber" [href]="'tel:' + phoneNumber">{{ phoneNumber }}</a></span>
        </div>
      </header>
      <section class="template-body-bg">

        <ng-container>
                  <div id="description" class="">
                    <!-- <div class="focus_label" [innerHTML]="data.description | capitalizeFirst"></div> -->
                    <div style="margin-bottom: 10px;">
                      <label>ESRS topic: </label>
                      <select class="custom-input" placeholder="Select topic" [(value)]="selected">
                        <option value="Climate Change">Climate Change</option>
                        <option value="Pollution ">Pollution </option>
                        <option value="Water and marine resources">Water and marine resources</option>
                        <option value="Biodiversity and ecosystem">Biodiversity and ecosystem</option>
                        <option value="Circular economy">Circular economy</option>
                        <option value="Own workforce">Own workforce</option>
                        <option value="Affected communities">Affected communities</option>
                        <option value="Consumers and end-users">Consumers and end-users</option>
                        <option value="Business conduct">Business conduct</option>
                      </select>
                    </div>
                    <button mat-flat-button class="upload-btn pad_10 start-btn mgn-up-20" (click)="startSurvey()">Get Started Now!</button>
                  </div>
                </ng-container>

    </section>

  </section>

  

</ng-container>
<ng-container>
  <section>
    <ng-template #congratsTemplate>
      <app-congratulations></app-congratulations>
    </ng-template>
  </section>
</ng-container>



