import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { environment } from '../../environments/environment.production';
import { environment } from '../../environments/environment'; //sma-attempt url from  normal env


@Injectable({
  providedIn: 'root'
})
export class TemplateViewerService {
  constructor(private http: HttpClient) { }
  apiUrl = environment.api_url;
  surveyUrl = environment.surveyUrl
  private api = `${this.apiUrl}/survey/encrypted`;
  newApiUrl = environment.dma_ixbrl_url;
  getTopicsApi = `${environment.dma_ixbrl_url}/topics/selectedTopics`;
  apiUrl_ixbrl = environment.api_url_dma_ixbrl_report;
  resultApi = `${this.apiUrl_ixbrl}/getIMAFMAByCtxAndUserId`;
  selectedItem: any[] = [];
  bubbleChartResultUrl = `${this.apiUrl_ixbrl}/reportingAvg`;



  //apis
  getSmaSurvey(surveyId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*', // This won't help with CORS
      'Content-Type': 'application/json'
    });
    return this.http.get<any>(`${this.api}/${surveyId}`, { headers });
  }

  UpdateSurvey(surveyData: any) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*', // This won't help with CORS
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(`${this.surveyUrl}`, surveyData, { headers });
  }

  fileUpload(formData: FormData): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', '*/*');
    headers = headers.append('Ssuite-Type', 'File-Upload');
    let url = this.apiUrl + '/images/uploadFiles';
    return this.http.post(url, formData, { headers: headers, responseType: 'text' })
  }


  getChildMenuItems(userId: any, ctxId: any, parentId: number): Observable<any> {
    const result = `${this.apiUrl_ixbrl}/poc-ima/menus/${userId}/${ctxId}/${parentId}`;
    return this.http.get(result);
  }
  getMenuItemsDynamic(): Observable<any> {
    const userId = environment.userId;
    const ctxId = environment.workspaceId;
    const result = `${this.apiUrl_ixbrl}/poc-ima/menus/${userId}/${ctxId}`;
    return this.http.get(result);

  }
  getTagsResult(ctxId: any, userId: any, tags: any): Observable<any> {
    const data = {
      ctxId,
      userId,
      tags
    };
    const url = `${this.resultApi}`;
    return this.http.post(url, data)
  }

  getBubbleChartResult(ctxId: any, userId: any): Observable<any> {
    const data = {
      ctxId,
      userId
    };
    const result = `${this.bubbleChartResultUrl}/${userId}/${ctxId}`;
    return this.http.post(result, data)
  }

  getTopics(ctxId: any, userId: any): Observable<any> {
    return this.http.get<any>(`${this.getTopicsApi}/${ctxId}/${userId}`);
  }

  getMateriality(ctxId: any, userId: any): Observable<any> {

    return this.http.get(`${this.newApiUrl}/topics/getMateriality/${ctxId}/${userId}`);
  }
}

