export const environment = {
    production: false,
    userId: 0,
    workspaceId: 0,
    api_url: 'https://api.survey.staging.sustain-suite.com/sma',
    surveyUrl: 'https://api.survey.staging.sustain-suite.com/sma/survey-attempt',
    language: undefined,
    baseURL: undefined,
    api_url_dma_ixbrl_report: 'https://api.bauer.sustain-suite.com/dma-api',
    dma_ixbrl_url: 'https://api.staging.dma-survey.sustain-suite.com/dma',
};